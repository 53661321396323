import { AppFunctionComponent } from "@vooom/core"
import React, { ChangeEvent } from "react"
import { SearchFields } from "../trips-search.definitions"
import { Icon } from "../../icon/icon"
import useLocationIcon from "@vooom/images/use-location.png"
import deleteIcon from "@vooom/images/delete.png"
import { InputWrapper, Input } from "./search-input.styles"

interface SearchInputProps {
  disabled: boolean
  fieldIdentifier: SearchFields
  label: string
  value: string
  onChange: (value: string, source: SearchFields) => void
  onLocationClick: (source: SearchFields) => void
  onDeleteClick: (source: SearchFields) => void
  onFocus: (source: SearchFields) => void
  usesCurrentLocation: boolean
  isLocationEnabled: boolean
}

export const SearchInput: AppFunctionComponent<SearchInputProps> = (props) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value, props.fieldIdentifier)
  }

  const onFocus = () => {
    props.onFocus(props.fieldIdentifier)
  }

  const onDeleteClick = () => {
    props.onDeleteClick(props.fieldIdentifier)
  }

  const onLocationClick = () => {
    props.onLocationClick(props.fieldIdentifier)
  }

  const canShowAnyIcon = !props.usesCurrentLocation && !props.disabled
  const canShowDeleteIcon = canShowAnyIcon && props.value.length > 0
  const canShowLocationIcon =
    canShowAnyIcon && props.isLocationEnabled && props.value.length === 0

  return (
    <InputWrapper>
      <Input
        disabled={props.disabled}
        textVariant={props.usesCurrentLocation ? "green" : "normal"}
        placeholder={props.label}
        value={props.value}
        onChange={onChange}
        onFocus={onFocus}
      />
      {canShowDeleteIcon && <Icon src={deleteIcon} onClick={onDeleteClick} />}
      {canShowLocationIcon && (
        <Icon src={useLocationIcon} onClick={onLocationClick} />
      )}
    </InputWrapper>
  )
}
