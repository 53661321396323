import { PoiSuggestion } from "../interfaces/suggestion.interface"

export const POI_SUGGESTIONS_STORAGE_KEY = "POI_SUGGESTIONS"
export const POI_SUGGESTIONS_STORAGE_LIMIT = 6

export const saveSuggestionToStorage = (suggestion: PoiSuggestion) => {
  const poiSuggestions = getSuggestionsFromStorage()
  const duplicateIndex = poiSuggestions.findIndex(
    (item) => item.id === suggestion.id
  )

  const isLimitExceeded = poiSuggestions.length >= POI_SUGGESTIONS_STORAGE_LIMIT
  const isSuggestionAlreadySaved = duplicateIndex !== -1

  if (isSuggestionAlreadySaved) {
    poiSuggestions.splice(duplicateIndex, 1)
  } else if (isLimitExceeded) {
    // SPLICE ONLY TO POI_SUGGESTIONS_STORAGE_LIMIT-1 ELEMENTS AND ADD A NEW ONE
    poiSuggestions.splice(
      1,
      poiSuggestions.length - POI_SUGGESTIONS_STORAGE_LIMIT + 1
    )
  }

  poiSuggestions.unshift(suggestion)

  localStorage.setItem(
    POI_SUGGESTIONS_STORAGE_KEY,
    JSON.stringify(poiSuggestions)
  )
}

export const getSuggestionsFromStorage = (): PoiSuggestion[] => {
  const storageResults = localStorage.getItem(POI_SUGGESTIONS_STORAGE_KEY)
  if (!storageResults) {
    return []
  }

  return JSON.parse(storageResults)
}
