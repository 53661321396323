import { TripStep } from "../../../interfaces/trip-step.interface"
import {
  resolveLineConfig,
  DEFAULT_LINE_CONFIG,
} from "../mappers/resolve-polylines"
import { theme } from "@vooom/ui"

interface LineOptionsBuilderConfig {
  addBorder: boolean
  isDisabled: boolean
  useOnlyStraightPath: boolean
  shouldOverlapOtherLines: boolean
}

export const buildLineOptions = (
  step: TripStep,
  config: LineOptionsBuilderConfig
) => {
  const {
    isDisabled,
    useOnlyStraightPath,
    shouldOverlapOtherLines,
    addBorder,
  } = config
  const lineConfig = resolveLineConfig(step)

  if (isDisabled) {
    lineConfig.strokeColor = theme.color.text.darkGray
  }

  if (addBorder) {
    lineConfig.strokeColor = theme.color.text.dark
    lineConfig.strokeWeight = DEFAULT_LINE_CONFIG.strokeWeight + 1
  }

  if (useOnlyStraightPath) {
    lineConfig.strokeOpacity = DEFAULT_LINE_CONFIG.strokeOpacity
    lineConfig.icons = undefined
  }

  lineConfig.zIndex = shouldOverlapOtherLines ? 2 : 1

  return lineConfig
}
