import { isDefined } from "./is-defined"

export type GraphQlSuccessResponse<T> = Required<
  Pick<GraphQlResponse<T>, "data">
>

export type GraphQlResponse<T> = {
  data?: T
  errors?: unknown
}

export const isSuccessGraphQlResponse = <T>(
  response: GraphQlResponse<T>
): response is GraphQlSuccessResponse<T> => isDefined(response.data)
