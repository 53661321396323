import React from "react"
import { AppFunctionComponent } from "@vooom/core"
import { TripPolyline } from "./trip-polyline.component"
import { TripStep } from "../../../interfaces/trip-step.interface"
import { Trip } from "../../../interfaces/trip.interface"

interface FullTripPolylineProps {
  trip: Trip
  step: TripStep
  isActive: boolean
}

export const FullTripPolyline: AppFunctionComponent<FullTripPolylineProps> = ({
  trip,
  step,
  isActive,
}) => {
  return (
    <>
      <TripPolyline
        isActive={isActive}
        step={step}
        trip={trip}
        shouldAddBorder={true}
      />
      <TripPolyline
        isActive={isActive}
        step={step}
        trip={trip}
        shouldAddBorder={false}
      />
    </>
  )
}
