import { AppFunctionComponent } from "@vooom/core"
import { TripStep } from "../../../interfaces/trip-step.interface"
import { Accordion } from "../../accordion/accordion"
import React from "react"
import { isPublicTransport } from "@vooom/utils"
import { LabeledText } from "../../labeled-text/labeled-text"
import { publicTransportTitle, transportTitle } from "./step-titles"

export const Stops: AppFunctionComponent<{ step: TripStep }> = ({ step }) => {
  const title = isPublicTransport(step)
    ? publicTransportTitle(step)
    : transportTitle(step.details)
  return isPublicTransport(step) ? (
    <Accordion collapsedTitle={title}>
      {step.details.stops.map((stop, index) => {
        if (index === 0 || index + 1 === step.details.stops.length) {
          return null
        }
        return (
          <div key={stop.name}>
            <LabeledText text={""} prefix={stop.name} />
          </div>
        )
      })}
    </Accordion>
  ) : (
    <LabeledText prefix={title} text={""} />
  )
}
