import React from "react"
import styled from "styled-components"

import { AppFunctionComponent } from "@vooom/core"
import { PoiSuggestion } from "../../../../interfaces/suggestion.interface"
import { Icon } from "../../../icon/icon"
import { backgroundColor } from "@vooom/ui"

const TextContent = styled.span``

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 8px 0px;
  border-radius: 10px;
  cursor: pointer;

  :hover,
  &.active {
    background-color: ${backgroundColor("footer")};
  }

  ${TextContent} {
    margin-left: 10px;
  }
`

interface AutosuggestionItemProps {
  item: SuggestionVisualItem
  onClick: (item: PoiSuggestion) => void
  active: boolean
}

export interface SuggestionVisualItem extends PoiSuggestion {
  icon: string
}

export const SuggestionsItem: AppFunctionComponent<AutosuggestionItemProps> = (
  props
) => (
  <ItemWrapper
    className={props.active ? "active" : ""}
    onClick={() => props.onClick(props.item)}
  >
    <Icon src={props.item.icon} variant="big" shadowed={true} shape={"round"} />
    <TextContent>{props.item.address}</TextContent>
  </ItemWrapper>
)
