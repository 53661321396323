import { MapVehicle } from "../../../../../../interfaces/map-vehicle.interface"
import { Root, DisplayName, DisplayIdentifier } from "./content.styles"
import React from "react"
import { AppFunctionComponent } from "@vooom/core"

interface Props {
  vehicle: MapVehicle
}

export const Content: AppFunctionComponent<Props> = ({
  vehicle: { displayName, displayIdentifier },
}) => {
  return (
    <Root>
      <DisplayName>{displayName}</DisplayName>
      <DisplayIdentifier>{displayIdentifier}</DisplayIdentifier>
    </Root>
  )
}
