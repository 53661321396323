import { AppFunctionComponent } from "@vooom/core"
import React from "react"
import styled from "styled-components"

import logo from "@vooom/images/vooom-icon.png"

const Image = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 6px;
  cursor: pointer;
`

const Link = styled.a`
  text-underline: none;
`

export const Logo: AppFunctionComponent<{}> = () => (
  <Link href="https://vooom.pl" target="_blank">
    <Image src={logo} alt="Vooom.pl" />
  </Link>
)
