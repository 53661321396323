import React from "react"
import { AppFunctionComponent } from "@vooom/core"
import { Trip } from "../../../interfaces/trip.interface"
import { isDefined } from "@vooom/utils"
import { FullTripPolyline } from "./full-trip-polyline.component"

interface TripsPolylinesProps {
  trips: Trip[]
  activeTripId?: number
}

export const TripsPolylines: AppFunctionComponent<TripsPolylinesProps> = ({
  trips,
  activeTripId: hoveredTripId,
}) => {
  const tripsPolylines = []
  for (const trip of trips) {
    const isTripActive = isDefined(hoveredTripId) && trip.id === hoveredTripId
    const tripPolylines = trip.steps.map((step, index) => (
      <FullTripPolyline
        key={`${trip.id}_${index}`}
        trip={trip}
        step={step}
        isActive={isTripActive}
      />
    ))

    tripsPolylines.push(...tripPolylines)
  }

  return <>{tripsPolylines}</>
}
