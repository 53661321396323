import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { TimeType } from "../trips-search.definitions"

const TimeTypeSelect = styled.select`
  border: thin solid gray;
  padding: 2px;
`

type SearchTimeTypeSelectProps = {
  onChange: (timeType: TimeType) => void
  value: TimeType
} & Pick<JSX.IntrinsicElements["select"], "id" | "name">

export const SearchTimeTypeSelect: FunctionComponent<SearchTimeTypeSelectProps> = ({
  onChange,
  value,
  ...restOfProps
}) => (
  <TimeTypeSelect
    value={value}
    onChange={(event) => onChange(event.target.value as TimeType)}
    {...restOfProps}
  >
    <option value="departure">Odjazd</option>
    <option value="arrival">Przyjazd</option>
  </TimeTypeSelect>
)
