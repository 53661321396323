import { AppFunctionComponent } from "@vooom/core"
import React from "react"
import {
  OutlinedLabel,
  SmoothyLabel,
  HeaderIcon,
  TripType,
} from "./labeled-icon.styles"

export enum LabeledIconVariant {
  Outlined = "outlined",
  Smoothy = "smoothy",
}

const Label: AppFunctionComponent<{
  variant: LabeledIconVariant
}> = ({ variant, children }) => {
  const ValidLabel =
    variant === LabeledIconVariant.Outlined ? OutlinedLabel : SmoothyLabel

  return <ValidLabel>{children}</ValidLabel>
}

export const LabeledIcon: AppFunctionComponent<{
  icon?: string
  label?: string
  variant?: LabeledIconVariant
}> = ({ icon, label, className, variant = LabeledIconVariant.Outlined }) => (
  <TripType className={className}>
    <HeaderIcon src={icon ?? ""} />
    {label && <Label variant={variant}>{label}</Label>}
  </TripType>
)
