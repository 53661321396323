import styled from "styled-components"
import { theme } from "@vooom/ui"

export const StepContainer = styled.div`
  padding: 14px 0;
`

export const Scrollable = styled.div`
  max-height: 60vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px 10px 48px;

  ${StepContainer}:last-child:after {
    content: none;
  }
`

export const Divider = styled.hr`
  height: 1px;
  width: 75%;
  background: ${theme.color.separator.tripSteps};
  border: none;
  margin-right: 0;
`
