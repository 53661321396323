import React from "react"
import {
  Container,
  Root,
  Arrow,
  DataContainer,
  MouseOutIndicator,
} from "./map-wrapper.styles"
import { AppFunctionComponent } from "@vooom/core"

interface Props {
  onMouseOut?: () => void
}

export const MapWrapper: AppFunctionComponent<Props> = ({
  children,
  onMouseOut = () => {},
}) => {
  return (
    <Root>
      <Container>
        <MouseOutIndicator onMouseOut={onMouseOut} />
        <DataContainer>{children}</DataContainer>
        <Arrow />
      </Container>
    </Root>
  )
}
