import { textColor } from "@vooom/ui"
import styled, { css } from "styled-components"
import { Icon } from "../icon/icon"

export const HeaderIcon = styled(Icon)`
  width: 42px;
  height: 42px;
`

const baseLabelCss = css`
  white-space: nowrap;
`

export const OutlinedLabel = styled.span`
  border-radius: 16px;
  box-shadow: 0 0 6px 0 #dde0e3;
  padding: 5px 10px;
  height: fit-content;
  margin-left: 6px;
  ${baseLabelCss}
`

export const SmoothyLabel = styled.span`
  border-radius: 16px;
  margin-left: 4px;
  height: fit-content;
  color: ${textColor("faded")};
  font-size: 0.9rem;
  ${baseLabelCss}
`

export const TripType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
