import { useCallback, useState } from "react"
import { GetSuggestionsParams } from "../interfaces/geocoder-params.interface"
import { GeocoderAutocompleteResponse } from "../interfaces/geocoder-autocomplete-response.interface"
import { PoiSuggestion } from "../interfaces/suggestion.interface"
import { getAutocompleteSuggestions } from "./geocoder.requests"
import { getSuggestionsFromStorage } from "./poi-suggestions-storage"
import { useLogger } from "./logger/logger.hook"
import { useLanguage } from "../language/use-language"

export const FROM_STORAGE_ADDRESS_LENGTH_LIMIT = 3

export enum SuggestionResultType {
  Geocoder = "geocoder",
  Local = "local",
}

export interface SuggestionsResult {
  type: SuggestionResultType
  items: PoiSuggestion[]
}

export const predictionsToSuggestions = (
  response: GeocoderAutocompleteResponse
): PoiSuggestion[] =>
  response.predictions.map(({ description, id, place_id, reference }) => ({
    address: description,
    id,
    place_id,
    reference,
  }))

export const useSuggestions = () => {
  const [pending, setPending] = useState(false)
  const [error, setError] = useState<any>(undefined)
  const [suggestionsResult, setSuggestionResults] = useState<
    SuggestionsResult
  >()
  const logger = useLogger()
  const language = useLanguage()

  const getSuggestions = useCallback(
    (params: GetSuggestionsParams) => {
      setPending(true)
      setSuggestionResults(undefined)
      setError(undefined)

      if (params.address.length <= FROM_STORAGE_ADDRESS_LENGTH_LIMIT) {
        setSuggestionResults({
          items: getSuggestionsFromStorage(),
          type: SuggestionResultType.Local,
        })
        setPending(false)
        return
      }

      return getAutocompleteSuggestions({
        address: params.address,
        sessionToken: params.sessionToken,
        language,
      })
        .then((response) => predictionsToSuggestions(response))
        .then((foundSuggestions) =>
          setSuggestionResults({
            items: foundSuggestions,
            type: SuggestionResultType.Geocoder,
          })
        )
        .catch((error) => {
          setError(error)
          logger.error(
            "Unexpected error occurred when getting autocomplete suggestions",
            {
              error,
              params,
            }
          )
        })
        .finally(() => setPending(false))
    },
    [logger, language]
  )

  const clearSuggestions = () => {
    setSuggestionResults(undefined)
  }

  return { pending, error, suggestionsResult, getSuggestions, clearSuggestions }
}
