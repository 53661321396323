import { Duration } from "iso8601-duration"

/**
 * Only up to `hours`
 *
 * i.e. passing more than 1 day difference will return
 * hours: 26
 * minutes: x
 * seconds: 1
 *
 */
export const dateToDuration = (
  date: Date,
  diffTo: Date = new Date()
): Duration => {
  let diff = Math.abs((date.getTime() - diffTo.getTime()) / 1000)

  const hours = Math.floor(diff / 3600)
  diff -= hours * 3600

  const minutes = Math.floor(diff / 60)
  diff -= minutes * 60

  const seconds = diff % 60

  return {
    seconds,
    minutes,
    hours,
  }
}
