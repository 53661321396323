import React from "react"
import { AppFunctionComponent } from "@vooom/core"
import styled, { css, keyframes } from "styled-components"

interface Props {
  variant?: "normal" | "big"
}

const shimmerFrames = keyframes`
    0% {
        background-position: -468px 0
    }
    
    100% {
        background-position: 468px 0
    }
`
const shimmerAnimation = css`
  animation-duration: 2.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${shimmerFrames};
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 0%, #f0f0f0 50%, #f6f6f6 100%);
  position: relative;
`

const Wrapper = styled.div`
  width: 300px;
`

const Rectangle = styled.div<{
  variant?: "small" | "normal"
  width: SupportedWidths
}>`
  margin: 16px 0px;
  height: 40px;
  background-color: #f6f6f6;

  ${shimmerAnimation};

  ${(props) =>
    props.variant === "small" &&
    css`
      height: 20px;
    `};

  ${(props) =>
    css`
      width: ${props.width};
    `};
`

type SupportedWidths = "50%" | "60%" | "75%" | "100%"

export const Loader: AppFunctionComponent<Props> = (props) => {
  if (props.variant === "big") {
    return (
      <Wrapper>
        <Rectangle variant="small" width="50%" />
        <Rectangle variant="normal" width="60%" />
        <Rectangle variant="small" width="50%" />
        <Rectangle variant="small" width="75%" />
        <Rectangle variant="normal" width="100%" />
        <Rectangle variant="small" width="50%" />
        <Rectangle variant="small" width="75%" />
        <Rectangle variant="normal" width="60%" />
        <Rectangle variant="small" width="75%" />
        <Rectangle variant="small" width="50%" />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Rectangle variant="small" width="50%" />
      <Rectangle variant="normal" width="75%" />
      <Rectangle variant="normal" width="75%" />
      <Rectangle variant="normal" width="75%" />
    </Wrapper>
  )
}
