import { TripStep } from "../../../../../../../interfaces/trip-step.interface"
import React from "react"
import { AppFunctionComponent } from "@vooom/core"
import { TripStepDetailsPublicTransport } from "../../../../../../../interfaces/trip-step-details-public-transport.interface"
import { LabeledIcon } from "../../../../../../labeled-icon"
import { resolveStepPoiIcon } from "../../../../../../map/mappers/resolve-step-poi-icon"

type Props = {
  step: TripStep<TripStepDetailsPublicTransport>
}

export const PublicTransportStep: AppFunctionComponent<Props> = ({ step }) => {
  const icon = resolveStepPoiIcon(step)
  return <LabeledIcon label={step.details.label} icon={icon} />
}
