import { isDefined } from "@vooom/utils"
import { SentryObject, initializeSentryObject } from "./sentry"
import { Severity } from "@sentry/browser"

export type Context = { error: Error } & Record<any, any>

class Logger {
  constructor(private readonly sentry: SentryObject | undefined) {}

  error(message: string, context: Context) {
    return this.log(message, context, Severity.Error)
  }

  warning(message: string, context: Context) {
    return this.log(message, context, Severity.Warning)
  }

  info(message: string, context: Context) {
    return this.log(message, context, Severity.Info)
  }

  critical(message: string, context: Context) {
    return this.log(message, context, Severity.Critical)
  }

  private log(message: string, context: Context, severity: Severity) {
    if (isDefined(this.sentry)) {
      this.sentry.withScope((scope) => {
        scope.setExtra("context", context)
        this.sentry!.captureMessage(message, severity)
      })
    } else {
      console.log(`[${severity}]${message}`, context)
    }
  }
}

export const logger = new Logger(
  initializeSentryObject(process.env.REACT_APP_SENTRY_DSN || "")
)
