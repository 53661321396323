import { AppFunctionComponent } from "@vooom/core"
import { TripStep } from "../../../../../../interfaces/trip-step.interface"
import React from "react"
import { StandardStep, PublicTransportStep } from "./components"
import { Arrow, Root, Spacer } from "./horizontal-steps.styles"
import { isPublicTransport } from "@vooom/utils"

import forwardArrow from "@vooom/images/arrow/chevron-black@2x.png"

type Props = {
  steps: TripStep[]
}

export const HorizontalSteps: AppFunctionComponent<Props> = ({ steps }) => (
  <Root>
    {steps.map((step, index) => (
      <React.Fragment key={index}>
        {isPublicTransport(step) ? (
          <PublicTransportStep step={step} />
        ) : (
          <StandardStep step={step} />
        )}
        {index + 1 !== steps.length && <Arrow src={forwardArrow} />}
      </React.Fragment>
    ))}
    <Spacer />
  </Root>
)
