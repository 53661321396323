import { AppFunctionComponent } from "@vooom/core"
import React from "react"
import { Small } from "./departure-time.styles"
import { LabeledText } from "../../../../../labeled-text/labeled-text"
import { dateToDuration, durationToText } from "@vooom/utils"

type Props = {
  date: Date
  locationName?: string
}

export const DepartureTime: AppFunctionComponent<Props> = ({
  date,
  locationName,
}) => {
  const alreadyDeparted = date < new Date()
  const departureIn = durationToText(dateToDuration(date))
  const prefix = alreadyDeparted ? "Odjechał " : "Odjedzie za "
  const suffix = alreadyDeparted ? " temu" : ""
  const location = locationName ? ` z ${locationName}` : ""
  return (
    <Small>
      <LabeledText
        prefix={prefix}
        text={departureIn}
        sufix={`${suffix}${location}`}
      />
    </Small>
  )
}
