import styled, { css } from "styled-components"
import { TextInput } from "@vooom/ui"
import { supportColor, backgroundColor, shadowColor } from "@vooom/ui"
import { Icon } from "../icon/icon"

export const SearchContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 26px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 20px 0 ${shadowColor("box")};

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${backgroundColor("footer")};
      align-items: start;
    `}
`

export const LocationsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`

export const InputsContainer = styled.div`
  width: 100%;

  ${TextInput} {
    width: 100%;
    border-bottom: none;
  }
`

export const DateAndTimeContainer = styled.div`
  width: 100%;
  margin-top: 10px;
`

export const TimeTypeContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`

export const InputDivider = styled.div`
  border: 1px solid ${supportColor("inputBorder")};
  margin: 12px 0px;
`

export const BackIcon = styled(Icon)`
  margin-right: 16px;
`

export const SwitchIcon = styled(Icon)`
  margin-left: 25px;
`
export const NotFound = styled.div`
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TimeInput = styled.input`
  margin-left: 10px;
  border: thin solid gray;
  padding: 2px;
`
