import { AutocompleteSuggestionsParams } from "../interfaces/geocoder-params.interface"
import { GeocoderAutocompleteResponse } from "../interfaces/geocoder-autocomplete-response.interface"

export const getAutocompleteSuggestions = ({
  address,
  sessionToken,
  language,
}: AutocompleteSuggestionsParams) => {
  const autocompleteParams = new URLSearchParams({
    input: address,
    sessionToken,
    requestOrigin: "planner",
  })

  return fetch(
    `${process.env.REACT_APP_API_URL}/v1/geocoder/autocomplete?${autocompleteParams}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": language,
      },
    }
  ).then<GeocoderAutocompleteResponse>((rawResponse) => rawResponse.json())
}
