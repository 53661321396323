import styled, { css } from "styled-components"
import { textColor } from "@vooom/ui"

export const InputWrapper = styled.div`
  display: flex;
  height: 24px;
`

export const Input = styled.input<{
  textVariant: "normal" | "green"
}>`
  width: 100%;
  border: none;
  display: block;
  font-size: 16px;
  line-height: 1;
  transition: 250ms border-bottom-color;
  padding: 0;
  -webkit-appearance: none;
  border-radius: 0;
  letter-spacing: 0.4px;
  color: ${textColor("dark")};
  margin-right: 10px;
  background-color: inherit;
  text-overflow: ellipsis;

  ${(props) => {
    if (props.textVariant === "green") {
      return css`
        color: ${textColor("green")};
      `
    }
    return css`
      color: ${textColor("dark")};
    `
  }}

  &::placeholder {
    color: ${textColor("darkGray")};
    letter-spacing: 0.4px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    font-size: 16px;
  }

  &:focus {
    outline: none;
  }
`
