import { AppFunctionComponent } from "@vooom/core"
import styled from "styled-components"
import { theme } from "@vooom/ui"
import React from "react"

const LabelText = styled.span`
  display: inline;
  color: ${theme.color.text.paragraph};
`
const Text = styled.span`
  display: inline;
  color: ${theme.color.text.primary};
`

const Placement = styled.div``

export const LabeledText: AppFunctionComponent<{
  prefix?: string
  sufix?: string
  text: string
}> = ({ prefix, sufix, text }) => (
  <Placement>
    {prefix && <LabelText>{prefix}</LabelText>}
    <Text>{text}</Text>
    {sufix && <LabelText>{sufix}</LabelText>}
  </Placement>
)
