import styled from "styled-components"
import { supportColor } from "../../../../../@ui/theme/theme-getters"
import CardContainer from "../../../../../@ui/components/base/card-container/card-container"

const iconSize = {
  width: 42,
  height: 61,
}

export const Root = styled.div`
  transform: translate(-50%, -100%);
`

export const DataContainer = styled(CardContainer)`
  padding: 1rem;
  border-bottom: 2px solid ${supportColor("violentViolet")};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: ${iconSize.height}px;
  position: relative;
`

export const MouseOutIndicator = styled.div`
  position: absolute;
  bottom: 0;
  left: calc(50% - ${iconSize.width / 2}px);
  width: ${iconSize.width}px;
  height: ${iconSize.height}px;
`

export const Arrow = styled.div`
  width: 0;
  height: 0;
  border-top: solid 10px ${supportColor("violentViolet")};
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
`
