import React, { useState } from "react"
import { MapVehicle } from "../../../interfaces/map-vehicle.interface"
import { OverlayView } from "@react-google-maps/api"
import { VehiclesDrawer } from "./vehicles-drawer/vehicles-drawer.component"
import { VehicleDetailsMapWrapper, VehicleDetails } from "./vehicle-details"
import { AppFunctionComponent } from "@vooom/core"

export const Vehicles: AppFunctionComponent<{}> = () => {
  const [activeVehicle, setActiveVehicle] = useState<MapVehicle>()

  const handleVehicleMouseOver = (vehicle: MapVehicle) => {
    if (activeVehicle?.id !== vehicle.id) {
      setActiveVehicle(vehicle)
    }
  }
  const handleVehicleMouseOut = () => setActiveVehicle(undefined)

  return (
    <>
      <VehiclesDrawer onMouseOver={handleVehicleMouseOver} />
      {activeVehicle && (
        <OverlayView
          position={{
            lat: activeVehicle.latitude,
            lng: activeVehicle.longitude,
          }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <VehicleDetailsMapWrapper onMouseOut={handleVehicleMouseOut}>
            <VehicleDetails vehicle={activeVehicle} />
          </VehicleDetailsMapWrapper>
        </OverlayView>
      )}
    </>
  )
}
