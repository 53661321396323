import { TripsResponse } from "../interfaces/trips.response.interface"
import { parse } from "iso8601-duration"
import { StepType } from "../enums/step-type.enum"
import { TripStepDetailsShared } from "../interfaces/trip-step-details-shared"
import { TripStepDetailsPublicTransport } from "../interfaces/trip-step-details-public-transport.interface"
import { PublicTransportType } from "../enums/public-transport-type.enum"
import { TripStep } from "../interfaces/trip-step.interface"
import { Trip, RawTrip } from "../interfaces/trip.interface"
import { TripStepDetailsByFoot } from "../interfaces/trip-step-details-foot.interface"
import { TripStepDetailsByVehicle } from "../interfaces/trip-step-details-vehicle.interface"
import { isDefined, generateUniqueId } from "@vooom/utils"
import { logger } from "./logger/logger"

export const parseTripsResponse = (
  data: Partial<TripsResponse>
): TripsResponse => {
  const trips: RawTrip[] = (data.items ?? [])
    .map((trip: Partial<Trip>) => {
      try {
        const duration = parse(trip.duration as string)
        const steps = trip.steps!.map(
          (step: any): TripStep => {
            const knownType = Object.values(StepType).includes(step.type)
            if (!knownType) {
              throw new Error(`Unrecognized type: ${step.type}`)
            }
            const resolvedType = step.type as StepType
            switch (resolvedType) {
              case StepType.ByPublicTransport:
                return {
                  type: resolvedType,
                  details: toPublicTransportDetails(step.details, resolvedType),
                }
              case StepType.ByFoot:
                return {
                  type: resolvedType,
                  details: toFootDetails(step.details, resolvedType),
                }
              case StepType.ByVehicle:
              case StepType.ByCar:
                return {
                  type: resolvedType,
                  details: toVehicleDetails(step.details, resolvedType),
                }
              default:
                return {
                  type: resolvedType,
                  details: toCommonStepDetails(step.details, resolvedType),
                }
            }
          }
        )
        return {
          duration,
          steps,
        }
      } catch (error) {
        logger.error("Unexpected error occured when parsing trips", {
          error,
          data,
        })
        return undefined
      }
    })
    .filter(isDefined)

  return {
    items: trips,
  }
}

export const rawTripsToTrips = (rawTrips: RawTrip[]): Trip[] => {
  return rawTrips.map((item) => ({
    ...item,
    id: generateUniqueId(),
  }))
}

const toFootDetails = (
  data: Partial<TripStepDetailsByFoot>,
  resolvedType: StepType.ByFoot
) => ({
  ...toCommonStepDetails(data, resolvedType),
  type: resolvedType,
  footsteps: data.footsteps?.toFixed(0),
})

const toVehicleDetails = (
  data: Partial<TripStepDetailsByVehicle>,
  resolvedType: StepType.ByVehicle | StepType.ByCar
) => ({
  ...toCommonStepDetails(data, resolvedType),
  type: resolvedType,
  provider: data.provider,
  displayIdentifier: data.displayIdentifier,
  vehicleType: data.vehicleType,
  providerIconUrl: data.providerIconUrl,
})

const toPublicTransportDetails = (
  data: Partial<TripStepDetailsPublicTransport>,
  type: StepType
): TripStepDetailsPublicTransport => ({
  ...toCommonStepDetails(data, type),
  type: StepType.ByPublicTransport,
  departure: data.departure ?? "",
  arrival: data.arrival ?? "",
  interval: data.interval ? parse(data.interval as string) : undefined,
  departures: data.departures ?? [],
  stops:
    data.stops?.map((stop) => ({
      location: {
        latitude: stop.location.latitude ?? 0,
        longitude: stop.location.longitude ?? 0,
        name: stop.location.name ?? "",
      },
      name: stop.name ?? "",
    })) ?? [],
  transportType: data.transportType as PublicTransportType,
})

const toCommonStepDetails = (
  data: Partial<TripStepDetailsShared>,
  type: StepType
): TripStepDetailsShared => ({
  path: data.path ?? "",
  duration: data.duration ? parse(data.duration as string) : parse("PT0H0M0S"),
  label: data.label ?? "",
  iconUrl: data.iconUrl ?? "",
  departure: data.departure ?? "",
  arrival: data.arrival ?? "",
  type,
  startPoint: {
    longitude: data?.startPoint?.longitude ?? 0,
    latitude: data?.startPoint?.latitude ?? 0,
    name: data?.startPoint?.name ?? "",
  },
  stopPoint: {
    longitude: data?.stopPoint?.longitude ?? 0,
    latitude: data?.stopPoint?.latitude ?? 0,
    name: data?.stopPoint?.name ?? "",
  },
  direction: {
    name: data?.direction?.name ?? "",
  },
})
