import { AppFunctionComponent } from "@vooom/core"
import { Trip } from "../../interfaces/trip.interface"
import React, { useContext } from "react"
import { TripItem, TripsList } from "./components/"
import { TripInteractionsContext } from "../../contexts/trips-interactions.context"
import { isDefined } from "@vooom/utils"

interface TripsProps {
  trips?: Trip[]
  hoveredTripId?: number
  isTripSelected: boolean
}

export const Trips: AppFunctionComponent<TripsProps> = ({
  trips,
  hoveredTripId,
  isTripSelected = false,
}) => {
  const { onTripHoverIn, onTripSelect, onTripHoverOut } = useContext(
    TripInteractionsContext
  )

  if (!isDefined(trips) || trips.length === 0 || isTripSelected) {
    return null
  }

  return (
    <TripsList>
      {trips.map((trip, index) => (
        <TripItem
          trip={trip}
          key={index}
          isHighlighted={trip.id === hoveredTripId}
          onHoverIn={onTripHoverIn}
          onHoverOut={onTripHoverOut}
          onClick={onTripSelect}
          withDepartureTime
          withDuration
        />
      ))}
    </TripsList>
  )
}
