export enum L10n {
  station = "station",
  stop = "stop",
}

const I18n: { [index in L10n]: string } = {
  station: "stacje",
  stop: "przystanki",
}

export const translate = (key: L10n, _?: number) => {
  return I18n[key]
}
