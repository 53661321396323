import { AppFunctionComponent } from "@vooom/core"
import dayjs from "dayjs"
import React from "react"
import { Trip } from "../../../../interfaces/trip.interface"
import { isDefined, durationToText, isPublicTransport } from "@vooom/utils"
import { LabeledText } from "../../../labeled-text/labeled-text"
import { DepartureTime, HorizontalSteps } from "./components"
import {
  Header,
  Informations,
  Root,
  Scrollable,
  Shadow,
} from "./trip-item.styles"

export type TripItemProps = {
  trip: Trip
  onClick?: (trip: Trip) => void
  onHoverIn?: (trip: Trip) => void
  onHoverOut?: () => void
  withDepartureTime?: boolean
  withDuration?: boolean
  withInPlaceTime?: boolean
  isHighlighted?: boolean
}

export const TripItem: AppFunctionComponent<TripItemProps> = ({
  trip,
  onClick,
  onHoverIn = () => null,
  onHoverOut = () => null,
  withDepartureTime = false,
  withDuration = false,
  withInPlaceTime = false,
  isHighlighted = false,
}) => {
  const handleOnClick = () => onClick && onClick(trip)
  const handleOnHoverIn = () => onHoverIn(trip)

  const { duration, steps } = trip
  const publicTransportStep = steps.find(isPublicTransport)
  const inPlaceDate = dayjs(
    trip.steps[trip.steps.length - 1].details.arrival
  ).format("HH:mm")
  return (
    <Root
      onMouseEnter={handleOnHoverIn}
      onMouseLeave={onHoverOut}
      onClick={handleOnClick}
      role="button"
      highlighted={isHighlighted}
      clickable={Boolean(onClick)}
    >
      <Scrollable withDepartureTime={withDepartureTime}>
        <HorizontalSteps steps={steps} />
      </Scrollable>
      <Informations>
        <Header>
          {withDuration && (
            <LabeledText prefix="Czas: " text={durationToText(duration)} />
          )}
          {withInPlaceTime && (
            <LabeledText prefix="Na miejscu: " text={inPlaceDate} />
          )}
        </Header>
        {withDepartureTime && isDefined(publicTransportStep) && (
          <DepartureTime
            date={dayjs(publicTransportStep.details.departure).toDate()}
            locationName={publicTransportStep.details.stops[0].name}
          />
        )}
      </Informations>
      <Shadow />
    </Root>
  )
}
