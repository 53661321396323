import { MapVehicle } from "../../../../../../interfaces/map-vehicle.interface"
import {
  Root,
  Image,
  InformationContainer,
  ProviderLogotype,
  ImageContainer,
  FuelContainer,
} from "./header.styles"
import React from "react"
import { LabeledText } from "../../../../../labeled-text/labeled-text"
import { Icon } from "../../../../../icon/icon"
import fuelIcon from "../../../../../../images/fuel.png"
import { isDefined } from "../../../../../../utils/is-defined"
import { VehicleType } from "../../../../../../interfaces/vehicle-type.enum"
import { AppFunctionComponent } from "@vooom/core"

interface Props {
  vehicle: MapVehicle
}

export const Header: AppFunctionComponent<Props> = ({
  vehicle: { image, range, provider, type, powerSourcePercentage },
}) => {
  const getValidFuelLabel = () => {
    if (type === VehicleType.UnPedalScooter) {
      return isDefined(powerSourcePercentage)
        ? `${(powerSourcePercentage * 100).toFixed(0)} %`
        : "-- %"
    }
    return isDefined(range) ? `${range.toFixed(1)} km` : "--.- km"
  }

  return (
    <Root>
      <ImageContainer>
        <Image src={image} />
      </ImageContainer>
      <InformationContainer>
        <FuelContainer>
          {/* TODO: use real fuel icon when mishka gives it*/}
          <Icon src={fuelIcon} variant="tiny" />
          <LabeledText text={getValidFuelLabel()} />
        </FuelContainer>
        <ProviderLogotype src={provider.logotype} />
      </InformationContainer>
    </Root>
  )
}
