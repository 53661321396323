import React, { useContext } from "react"
import { Polyline } from "@react-google-maps/api"
import { AppFunctionComponent } from "@vooom/core"
import { TripStep } from "../../../interfaces/trip-step.interface"
import { Trip } from "../../../interfaces/trip.interface"
import { TripInteractionsContext } from "../../../contexts/trips-interactions.context"
import { buildLineOptions } from "./line-options"

interface TripPolylineProps {
  trip: Trip
  step: TripStep
  isActive: boolean
  shouldAddBorder: boolean
}

export const TripPolyline: AppFunctionComponent<TripPolylineProps> = ({
  trip,
  step,
  isActive,
  shouldAddBorder,
}) => {
  const { onTripHoverIn, onTripSelect, onTripHoverOut } = useContext(
    TripInteractionsContext
  )

  const handleOnClick = () => {
    onTripSelect(trip)
  }

  const handleOnHoverIn = () => {
    onTripHoverIn(trip)
  }

  const handleOnHoverOut = () => {
    onTripHoverOut()
  }

  const lineOptions = buildLineOptions(step, {
    isDisabled: !isActive,
    useOnlyStraightPath: !isActive,
    shouldOverlapOtherLines: isActive,
    addBorder: shouldAddBorder,
  })

  return (
    <Polyline
      path={google.maps.geometry.encoding.decodePath(step.details.path)}
      options={lineOptions}
      onClick={handleOnClick}
      onMouseOver={handleOnHoverIn}
      onMouseOut={handleOnHoverOut}
    />
  )
}
