import React from "react"

import { Trip } from "../interfaces/trip.interface"

export interface TripInteractions {
  onTripHoverIn: (trip: Trip) => void
  onTripHoverOut: () => void
  onTripSelect: (trip: Trip) => void
}

export const TripInteractionsContext = React.createContext<TripInteractions>({
  onTripHoverIn: () => {},
  onTripHoverOut: () => {},
  onTripSelect: () => {},
})
