import * as firebase from "firebase/app"
import "firebase/analytics"

export const canActivateFirebase = () => {
  return (
    process.env.REACT_APP_FIREBASE_API_KEY &&
    process.env.REACT_APP_FIREBASE_AUTH_DOMAIN &&
    process.env.REACT_APP_FIREBASE_DATABASE_URL &&
    process.env.REACT_APP_FIREBASE_PROJECT_ID &&
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET &&
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID &&
    process.env.REACT_APP_FIREBASE_APP_ID &&
    process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  )
}

export const initFirebase = () => {
  const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  }
  firebase.initializeApp(config)
  firebase.analytics()
}
