import React from "react"
import styled from "styled-components"

import { AppFunctionComponent } from "@vooom/core"
import { Button } from "@vooom/ui"

const ButtonWrapper = styled.div`
  padding: 32px 20px 20px 20px;

  ${Button} {
    width: 100%;
  }
`

interface SearchButtonAreaProps {
  onSearchClick: () => void
  disabled: boolean
}

export const SearchButtonArea: AppFunctionComponent<SearchButtonAreaProps> = ({
  onSearchClick,
  disabled,
}) => (
  <ButtonWrapper>
    <Button onClick={onSearchClick} disabled={disabled}>
      Szukaj
    </Button>
  </ButtonWrapper>
)
