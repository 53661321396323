import styled from "styled-components"
import { backgroundColor, shadowColor } from "../../../theme/theme-getters"

const CardContainer = styled.div`
  padding: 2.5rem;
  border-radius: 3px;
  box-shadow: 0 10px 20px 0 ${shadowColor("box")};
  background: ${backgroundColor("light")};
`

export default CardContainer
