import styled from "styled-components"
import { textColor } from "@vooom/ui"

export const TripsTitle = styled.h1`
  font-size: 0.8em;
  color: ${textColor("faded")};
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.6px;
`
