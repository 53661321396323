import { AppFunctionComponent } from "@vooom/core"
import { Trip } from "../../../interfaces/trip.interface"
import { useGoogleMap } from "@react-google-maps/api"
import * as turf from "@turf/helpers"
import envelope from "@turf/envelope"
import { isDefined } from "@vooom/utils"
import { useEffect } from "react"

export const MapFitter: AppFunctionComponent<{ trip: Trip }> = ({ trip }) => {
  const mapInstance = useGoogleMap()

  useEffect(() => {
    if (!isDefined(mapInstance)) {
      return
    }

    const points = turf.featureCollection(
      trip.steps.flatMap((step) => {
        const { latitude, longitude } = step.details.startPoint
        const { latitude: lat2, longitude: lng2 } = step.details.stopPoint
        return [turf.point([latitude, longitude]), turf.point([lat2, lng2])]
      })
    )

    /** geometry.coordinates[0] =>
   * 0: Array(5)
   0: (2) [52.1651873, 20.9922337]  // sw
   1: (2) [52.1958437, 20.9922337]
   2: (2) [52.1958437, 21.0173153]  // ne
   3: (2) [52.1651873, 21.0173153]
   4: (2) [52.1651873, 20.9922337]
   */
    const { geometry } = envelope(points)

    if (!isDefined(geometry)) {
      return
    }

    const zooom = mapInstance.getZoom()
    const shiftRightBy = zooom ? 1 / (zooom * 9) : 0
    mapInstance?.fitBounds(
      new google.maps.LatLngBounds(
        {
          lat: geometry.coordinates[0][0][0],
          lng: geometry.coordinates[0][0][1] - shiftRightBy,
        },
        {
          lat: geometry.coordinates[0][2][0],
          lng: geometry.coordinates[0][2][1] - shiftRightBy,
        }
      ),
      200
    )
  }, [trip, mapInstance])

  return null
}
