import { TripStep } from "../../../interfaces/trip-step.interface"
import { StepType } from "../../../enums/step-type.enum"
import { TripStepDetailsByVehicle } from "../../../interfaces/trip-step-details-vehicle.interface"
import { VehicleType } from "../../../interfaces/vehicle-type.enum"
import { isVehicle } from "@vooom/utils"
import { theme } from "@vooom/ui"

const resolveVehicleColor = (step: TripStepDetailsByVehicle): string => {
  switch (step.vehicleType) {
    case VehicleType.Bike:
      return theme.color.vehicleType.bikes
    case VehicleType.Scooter:
      return theme.color.vehicleType.scooter
    case VehicleType.UnPedalScooter:
      return theme.color.vehicleType.moped
    case VehicleType.Car:
    default:
      return theme.color.vehicleType.car
  }
}

export const resolveLineColorFromStep = (step: TripStep): string => {
  switch (step.type) {
    case StepType.ByCar:
    case StepType.ByVehicle:
      return isVehicle(step)
        ? resolveVehicleColor(step.details)
        : theme.color.vehicleType.car
    case StepType.ByFoot:
      return theme.color.text.dark
    case StepType.ByPublicTransport:
      return theme.color.support.crazyOrange
    default:
      return theme.color.text.error
  }
}
