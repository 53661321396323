import { TripStep } from "../../../interfaces/trip-step.interface"
import { LabeledText } from "../../labeled-text/labeled-text"
import { Stops } from "./stops.component"
import finishIcon from "@vooom/images/finish-icon.png"
import React from "react"
import { isoDateToTime } from "@vooom/utils"
import { AppFunctionComponent } from "@vooom/core"
import {
  Place,
  PlaceDescription,
  PlaceMiddleStops,
  FirstPlaceWithDot,
  LastPlaceWithDot,
  StepTimes,
  Places,
  TripContainer,
  TripFinishIcon,
} from "./step-details.styles"
import { StopDot } from "./stop-dot.component"

export const StepDetails: AppFunctionComponent<{
  step: TripStep
  isLastSegment: boolean
}> = ({ step, isLastSegment = false }) => {
  const startPlace = step.details.startPoint.name || "Początek"
  const finishPlace = step.details.stopPoint.name || "Koniec"

  const starTime = isoDateToTime(step.details.departure)
  const stopTime = isoDateToTime(step.details.arrival)

  return (
    <TripContainer>
      <StepTimes>
        <LabeledText prefix={starTime} text="" />
        <LabeledText prefix={stopTime} text="" />
      </StepTimes>
      <Places>
        <Place>
          <FirstPlaceWithDot>
            <StopDot />
            <PlaceDescription>{startPlace}</PlaceDescription>
          </FirstPlaceWithDot>
          <PlaceMiddleStops>
            <Stops step={step} />
          </PlaceMiddleStops>
        </Place>
        <Place>
          <LastPlaceWithDot>
            {isLastSegment ? <TripFinishIcon src={finishIcon} /> : <StopDot />}
            <PlaceDescription>{finishPlace}</PlaceDescription>
          </LastPlaceWithDot>
        </Place>
      </Places>
    </TripContainer>
  )
}
