import { VehicleType } from "../../../../interfaces/vehicle-type.enum"
import car from "../../../../images/pins/car-pin.svg"
import bike from "../../../../images/pins/bike-pin.svg"
import scooter from "../../../../images/pins/scooter-pin.svg"
import unpedalScooter from "../../../../images/pins/unpedal-scooter-pin.svg"
import { Vehicle } from "../../../../interfaces/vehicle.interface"

export const resolveVehiclePinIcon = (
  vehicle: Partial<Vehicle> & Pick<Vehicle, "type">
): string => {
  switch (vehicle.type) {
    case VehicleType.Bike:
      return bike
    case VehicleType.Scooter:
      return scooter
    case VehicleType.UnPedalScooter:
      return unpedalScooter
    case VehicleType.Car:
    default:
      return car
  }
}
