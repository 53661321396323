import styled, { css } from "styled-components"
import { backgroundColor } from "@vooom/ui"
import { Icon } from "../../../icon/icon"

export const Informations = styled.div`
  padding-left: 6px;
  position: absolute;
  bottom: 10px;
  left: 10px;
`
export const Header = styled.div`
  display: flex;
  > * {
    margin-right: 10px;
  }
`

export const Shadow = styled.div`
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
`

const RootHighlightedColor = css`
  background-color: ${backgroundColor("hover")};
`

export const Root = styled.div<{
  clickable: boolean
  highlighted?: boolean
}>`
  border-top: 1px solid ${backgroundColor("hover")};
  position: relative;
  background: ${backgroundColor("light")};
  transition: 0.25s linear;
  box-shadow: 0px 20px 35px 0px rgba(0, 0, 0, 0.04);

  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
      &:hover {
        ${RootHighlightedColor}
      }
    `}

  ${(props) => props.highlighted && RootHighlightedColor}
`

export const Scrollable = styled.div<{
  withDepartureTime: boolean
}>`
  overflow-x: scroll;
  padding-bottom: ${(props) => (props.withDepartureTime ? "50px" : "35px")};

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
`

export const BackArrow = styled(Icon)`
  cursor: pointer;
  top: 25px;
  left: 12px;
  position: absolute;
  border-radius: 100%;

  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
`
