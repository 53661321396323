import { Root } from "./details.styles"
import React from "react"
import { MapVehicle } from "../../../../../interfaces/map-vehicle.interface"
import { Header } from "./header/header.component"
import { Content } from "./content/content.component"
import { AppFunctionComponent } from "@vooom/core"

interface Props {
  lat?: number
  lng?: number
  vehicle: MapVehicle
}

export const Details: AppFunctionComponent<Props> = ({ vehicle }) => {
  return (
    <Root>
      <Header vehicle={vehicle} />
      <Content vehicle={vehicle} />
    </Root>
  )
}
