import styled from "styled-components"
import { backgroundColor } from "../../../../../@ui/theme/theme-getters"

export const Root = styled.div`
  background: ${backgroundColor("light")};
  display: flex;
  flex-direction: column;
  width: 240px;
`

export const Box = styled.div``
