import { useEffect, useRef, useState } from "react"
import { Nullable } from "@vooom/utils"
import { Location } from "../interfaces/location.interface"
import { BrowserGeolocation } from "../interfaces/browser-geolocation.interface"

export const useGeolocation = (): BrowserGeolocation => {
  let mounted = useRef<boolean>(true)
  const [currentLocation, setLocation] = useState<Location>()
  const [isLocationEnabled, setIsLocationEnabled] = useState<boolean>(false)

  const onEvent: PositionCallback = (event) => {
    setIsLocationEnabled(true)
    const { latitude, longitude } = event.coords
    if (mounted.current) {
      setLocation({
        latitude: latitude,
        longitude: longitude,
      })
    }
  }

  const onError = (error: any) => {
    setIsLocationEnabled(false)
  }

  useEffect(() => {
    let watchId: Nullable<number> = null
    navigator.geolocation.getCurrentPosition(onEvent, onError)
    watchId = navigator.geolocation.watchPosition(onEvent, onError)

    return () => {
      mounted.current = false
      if (watchId) {
        navigator.geolocation.clearWatch(watchId)
      }
    }
  }, [mounted])

  return { currentLocation, isLocationEnabled }
}
