const theme = {
  color: {
    text: {
      primary: "#0f0f1a",
      paragraph: "#7f8599",
      faded: "#999999",
      hover: "#4786e6",
      link: "#4786e6",
      dark: "#121b26",
      inverted: "#ffffff",
      error: "#fb4b53",
      darkGray: "#8896a3",
      green: "#00C96F",
    },
    mainGradient: {
      start: "#52c5b7",
      middle: "#4786e6",
      end: "#7045d3",
      overlay: "rgba(75, 75, 82, 0.1)",
    },
    support: {
      actionable: "#00c96f",
      hoveredActionable: "#2bcc84",
      activeActionable: "#16a059",
      politicalBlue: "#03cbff",
      crazyOrange: "#ef9517",
      violentViolet: "#881df4",
      inputBorder: "#ebebeb",
      dot: "#7045d3",
    },
    background: {
      light: "#ffffff",
      lighter: "rgba(255, 255, 255, 0.05)",
      hover: "#f7f8fb",
      dark: "#19253e",
      footer: "#f7f8fb",
      quote: "#243047",
    },
    shadow: {
      box: "rgba(42, 44, 50, 0.16)",
    },
    separator: {
      tripSteps: "rgba(236, 239, 242, 1)",
    },
    vehicleType: {
      car: "#8F20FF",
      scooter: "#00E195",
      moped: "#00CAFF",
      bikes: "#4646C8",
      public: "#F29718",
    },
  },
  layout: {
    width: "73.75rem",
  },
  breakpoint: {
    tablet: 48,
    mobile: 28,
  },
}

export type Theme = typeof theme

export default theme
