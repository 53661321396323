import { RootLayout, CardContainer } from "@vooom/ui"
import dayjs from "dayjs"

import React, { useState } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"
import styled from "styled-components"
import { Loader } from "./components/loader/loader.component"
import { Logo } from "./components/logo-overlay/logo"

import { PlannerMapComponent } from "./components/map/planner-map.component"
import { LocationRoot } from "./components/root-components/location-root.component"
import { TripDetails } from "./components/trips-details/trip-details.component"
import { TripsSearch } from "./components/trips-search/trips-search"
import { SearchTripsParams } from "./components/trips-search/trips-search.definitions"
import { TripItem, Trips } from "./components/trips/"
import {
  TripInteractions,
  TripInteractionsContext,
} from "./contexts/trips-interactions.context"
import { Location } from "./interfaces/location.interface"
import { TripRequestBody } from "./interfaces/trip-request-body.interface"
import { Trip } from "./interfaces/trip.interface"
import { usePlanner } from "./services/planner.hook"
import { isDefined } from "@vooom/utils"

const Overlay = styled.div`
  position: absolute;
  bottom: 16px;
  right: 64px;
`

const ContentWrapper = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
`

const Planner = styled(CardContainer)`
  max-width: 380px;
  padding: 0;
  position: absolute;
  left: 20px;
  right: 20px;
  top: 20px;
  max-height: 90%;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`

const Wrapper = styled.div`
  padding: 20px;
`

const SELECTED_CITY_DEFAULT_CENTER: Location = {
  latitude: 51.9568107,
  longitude: 18.0804607,
}

const DEFAULT_ZOOM = 7

const App = () => {
  const { execute, trips, pending } = usePlanner()
  const [currentTrip, setCurrentTrip] = useState<Trip>()
  const [hoveredTripId, setHoveredTripId] = useState<number>()

  const onBack = () => {
    setCurrentTrip(undefined)
  }

  const tripInteractionsContextValue: TripInteractions = {
    onTripHoverIn: (trip: Trip) => {
      setHoveredTripId(trip.id)
    },
    onTripHoverOut: () => {
      setHoveredTripId(undefined)
    },
    onTripSelect: (trip: Trip) => {
      setCurrentTrip(trip)
      setHoveredTripId(undefined)
    },
  }

  const onSearchTrip = ({
    from,
    to,
    location: geolocation,
    timeType,
    date,
  }: SearchTripsParams) => {
    if (pending) {
      return
    }
    const preparedParams: TripRequestBody = {
      from,
      to,
      currentLocation: geolocation,
      date: dayjs(date).format(),
      timeMode: timeType,
    }

    setCurrentTrip(undefined)
    execute(preparedParams)
  }

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        // WARNING: Do not set limit props in case fo specific notifier implementation which check for doubled toasts
      />
      <RootLayout>
        <ContentWrapper>
          <LocationRoot>
            <TripInteractionsContext.Provider
              value={tripInteractionsContextValue}
            >
              <PlannerMapComponent
                center={SELECTED_CITY_DEFAULT_CENTER}
                zoom={DEFAULT_ZOOM}
                selectedTrip={currentTrip}
                trips={trips}
                hoveredTripId={hoveredTripId}
              />
              <Planner>
                <TripsSearch
                  onBack={onBack}
                  pending={pending}
                  disableTripsSearching={Boolean(currentTrip)}
                  onSearchTrip={onSearchTrip}
                  anyTripFound={isDefined(trips) && trips.length > 0}
                />
                {pending && (
                  <Wrapper>
                    <Loader variant="big" />
                  </Wrapper>
                )}
                <Trips
                  trips={trips}
                  hoveredTripId={hoveredTripId}
                  isTripSelected={isDefined(currentTrip)}
                />
                {currentTrip && (
                  <TripItem trip={currentTrip} withDuration withInPlaceTime />
                )}
                {currentTrip && <TripDetails trip={currentTrip} />}
              </Planner>
              <Overlay>
                <Logo />
              </Overlay>
            </TripInteractionsContext.Provider>
          </LocationRoot>
        </ContentWrapper>
      </RootLayout>
    </div>
  )
}

export default App
