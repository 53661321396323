import React, { useEffect, useState, useCallback } from "react"
import { Key } from "ts-key-enum"
import styled from "styled-components"
import { AppFunctionComponent } from "@vooom/core"
import {
  SuggestionsItem,
  SuggestionVisualItem,
} from "./suggestions-item.component"
import { PoiSuggestion } from "../../../../interfaces/suggestion.interface"
import { useComponentVisible } from "../../../../services/component-visibility.hook"
import { textColor } from "@vooom/ui"

const SuggestionsTitle = styled.p`
  color: ${textColor("darkGray")};
  font-weight: 500;
  margin: 0 0 12px;
`

const NothingFound = styled.div`
  color: ${textColor("darkGray")};
`

interface SuggestionProps {
  showNothingFoundLabel: boolean
  items: SuggestionVisualItem[]
  label: string
  onItemClick: (item: PoiSuggestion) => void
  onTurnOffRequest: () => void
}

export const Suggestions: AppFunctionComponent<SuggestionProps> = (props) => {
  const [activeIndex, setActiveIndex] = useState<number>()
  const { ref, isComponentVisible } = useComponentVisible(true)

  const handleKeyClick = useCallback(
    (event: KeyboardEvent) => {
      const currentItemsLength = props.items.length
      switch (event.key) {
        case Key.ArrowUp:
          if (activeIndex === undefined || activeIndex === 0) {
            setActiveIndex(currentItemsLength - 1)
          } else {
            setActiveIndex(activeIndex - 1)
          }
          break

        case Key.ArrowDown:
          if (
            activeIndex === undefined ||
            activeIndex === currentItemsLength - 1
          ) {
            setActiveIndex(0)
          } else {
            setActiveIndex(activeIndex + 1)
          }
          break

        case Key.Enter:
          const itemIndexToSelect = activeIndex ?? 0
          props.onItemClick(props.items[itemIndexToSelect])
          break
      }
    },
    [activeIndex, props]
  )

  useEffect(() => {
    if (!isComponentVisible) {
      props.onTurnOffRequest()
    }
  }, [props, isComponentVisible])

  useEffect(() => {
    document.addEventListener("keydown", handleKeyClick, true)
    return () => {
      document.removeEventListener("keydown", handleKeyClick, true)
    }
  }, [handleKeyClick])

  useEffect(() => {
    setActiveIndex(undefined)
  }, [props.items])

  if (props.showNothingFoundLabel) {
    return <NothingFound ref={ref}>Nic nie znaleziono!</NothingFound>
  }

  if (props.items.length === 0) {
    return null
  }

  const renderedItems = props.items.map((item, index) => (
    <div key={index}>
      <SuggestionsItem
        active={index === activeIndex}
        item={item}
        onClick={props.onItemClick}
      />
    </div>
  ))

  return (
    <div ref={ref}>
      <SuggestionsTitle>{props.label}</SuggestionsTitle>
      {renderedItems}
    </div>
  )
}
