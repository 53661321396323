import { AppFunctionComponent } from "@vooom/core"
import React from "react"
import styled from "styled-components"

const Root = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`

export const TripsList: AppFunctionComponent<{}> = ({ children }) => {
  return <Root> {children}</Root>
}
