import { TripStep } from "../../../interfaces/trip-step.interface"
import { StepType } from "../../../enums/step-type.enum"
import car from "@vooom/images/car.png"
import { TripStepDetailsPublicTransport } from "../../../interfaces/trip-step-details-public-transport.interface"
import { PublicTransportType } from "../../../enums/public-transport-type.enum"
import metro from "@vooom/images/metro.png"
import tram from "@vooom/images/tram.png"
import bus from "@vooom/images/bus.png"
import walk from "@vooom/images/walk.png"
import { isVehicle } from "@vooom/utils"
import { TripStepDetailsByVehicle } from "../../../interfaces/trip-step-details-vehicle.interface"
import { VehicleType } from "../../../interfaces/vehicle-type.enum"

const resolvePoiIconByVehicle = (details: TripStepDetailsByVehicle): string => {
  if (details.providerIconUrl) {
    return details.providerIconUrl
  }

  switch (details.vehicleType) {
    case VehicleType.Bike:
    case VehicleType.Scooter:
    case VehicleType.UnPedalScooter:
    case VehicleType.Car:
    default:
      return car
  }
}

export const resolveStepPoiIcon = (step: TripStep): string | undefined => {
  if (step.details.iconUrl) {
    return step.details.iconUrl
  }

  switch (step.type) {
    case StepType.ByCar:
    case StepType.ByVehicle:
      return isVehicle(step) ? resolvePoiIconByVehicle(step.details) : car
    case StepType.ByPublicTransport:
      switch ((step.details as TripStepDetailsPublicTransport).transportType) {
        case PublicTransportType.metro:
          return metro
        case PublicTransportType.tram:
          return tram
        case PublicTransportType.bus:
        default:
          return bus
      }
    case StepType.ByFoot:
      return walk
    default:
      return
  }
}
