import { useCallback, useState } from "react"
import { Trip } from "../interfaces/trip.interface"
import { TripRequestBody } from "../interfaces/trip-request-body.interface"
import { parseTripsResponse, rawTripsToTrips } from "./trips-parser"
import { useLogger } from "./logger/logger.hook"
import { useNotifier } from "./notifier.hook"
import { useLanguage } from "../language/use-language"

export const usePlanner = () => {
  const [pending, setPending] = useState(false)
  const [error, setError] = useState<any>(undefined)
  const [trips, setCurrentTrips] = useState<Trip[]>()
  const logger = useLogger()
  const notifier = useNotifier()
  const language = useLanguage()

  const execute = useCallback(
    (body: TripRequestBody) => {
      setPending(true)
      setCurrentTrips(undefined)
      setError(undefined)
      return fetch(`${process.env.REACT_APP_API_URL}/v1/planner/trips`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Accept-Language": language,
        },
        body: JSON.stringify(body),
      })
        .then((rawResponse) => rawResponse.json())
        .then((response) => {
          const { items: rawTrips } = parseTripsResponse(response) ?? {}
          const preparedTrips = rawTripsToTrips(rawTrips)
          setCurrentTrips(preparedTrips)
          return preparedTrips
        })
        .catch((error) => {
          setError(error)
          notifier.error("Nie mogliśmy pobrać tras, spróbuj za chwilę 🦅")
          logger.error("Unknown error occurred when fetching trips", {
            error,
          })
        })
        .finally(() => setPending(false))
    },
    [logger, notifier, language]
  )

  return { pending, error, trips, execute }
}
