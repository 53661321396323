import styled, { css } from "styled-components"
import { Icon } from "../../icon/icon"
import { theme } from "@vooom/ui"

export const Places = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
`

const placeWithDotBaseCSs = css`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:before {
    z-index: -1;
    content: "";
    position: absolute;
    border-left: 1px solid ${theme.color.separator.tripSteps};
    left: 8px;
    top: 8px;
    bottom: -8px;
  }
`

export const FirstPlaceWithDot = styled.div`
  & > svg {
    align-self: flex-start;
  }
  ${placeWithDotBaseCSs}
`

export const LastPlaceWithDot = styled.div`
  & > svg {
    align-self: flex-end;
  }
  ${placeWithDotBaseCSs}
`

export const PlaceDescription = styled.span``

export const PlaceMiddleStops = styled.div`
  padding: 10px 0 14px 22px;
`

export const Place = styled.li`
  position: relative;
  width: 100%;
  z-index: 1;

  &:last-child ${LastPlaceWithDot}:before {
    bottom: 8px;
  }

  &:last-child {
    padding-bottom: 0;
  }

  ${PlaceDescription} {
    margin-left: 5px;
  }
`
export const TripFinishIcon = styled(Icon)`
  width: 16px;
  height: 16px;
`

export const TripContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${Places} {
    margin-left: 5px;
  }
`

export const StepTimes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space: nowrap;
`
