import Collapse from "@material-ui/core/Collapse"
import { AppFunctionComponent } from "@vooom/core"
import React, { useState } from "react"
import arrowNormal from "@vooom/images/arrow/arrow-normal.png"
import { LabeledText } from "../labeled-text/labeled-text"
import styled from "styled-components"
import { Icon } from "../icon/icon"

interface AccordionProps {
  collapsedTitle: string
}

const Root = styled.div`
  position: relative;
`

const RotatingArrow = styled(Icon)<{ isActive: boolean }>`
  transition: all 0.3s;
  transform: rotate(${(props) => (props.isActive ? 180 : 0)}deg);
  margin-right: 12px;
`

const IconContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
`

export const Accordion: AppFunctionComponent<AccordionProps> = ({
  collapsedTitle,
  children,
}) => {
  const [opened, setOpened] = useState(false)

  return (
    <Root onClick={() => setOpened(!opened)}>
      <IconContainer>
        <RotatingArrow isActive={opened} src={arrowNormal} />
      </IconContainer>
      <Collapse in={!opened}>
        <LabeledText prefix={collapsedTitle} text={""} />
      </Collapse>
      <Collapse in={opened}>{children}</Collapse>
    </Root>
  )
}
