import { Duration } from "iso8601-duration"

export const durationToText = (duration?: Duration): string => {
  if (!duration) {
    return ""
  }

  const hasSomeSeconds = (duration.seconds ?? 0) > 0
  const hasSomeMinutes = (duration.minutes ?? 0) > 0
  const hasSomeHours = (duration.hours ?? 0) > 0
  if (!hasSomeHours && !hasSomeMinutes && hasSomeSeconds) {
    return "< 1 min"
  }

  let result = ""
  if (hasSomeHours) {
    result += `${duration.hours} h`
  }

  if (hasSomeHours && hasSomeMinutes) {
    result += ` `
  }

  if (hasSomeMinutes) {
    result += `${duration.minutes} min`
  }

  return result
}
