import styled from "styled-components"
import { mainGradient, textColor } from "../../../theme/theme-getters"

const Button = styled.button`
  border: none;
  border-radius: 30px;
  padding: 0 3rem;
  height: 3.75rem;
  background: radial-gradient(
    circle at 107% 109%,
    ${mainGradient("start")},
    ${mainGradient("middle")} 51%,
    ${mainGradient("end")}
  );
  color: ${textColor("inverted")};
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;

  &:hover {
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.3)
      ),
      radial-gradient(
        circle at 107% 109%,
        ${mainGradient("start")},
        ${mainGradient("middle")} 51%,
        ${mainGradient("end")}
      );
  }

  &:active {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.2)
      ),
      radial-gradient(
        circle at 107% 109%,
        ${mainGradient("start")},
        ${mainGradient("middle")} 51%,
        ${mainGradient("end")}
      );
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    border: solid 2px rgba(0, 0, 0, 0.1);
    padding-top: calc(1.1rem - 2px);
    padding-bottom: calc(1.1rem - 2px);
    background: #dadada;
    cursor: not-allowed;
  }
`

export default Button
