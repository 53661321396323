/// <reference types="googlemaps" />
import { TripStep } from "../../../interfaces/trip-step.interface"
import { resolveLineColorFromStep } from "./resolve-line-color"
import { StepType } from "../../../enums/step-type.enum"

const lineSymbol = {
  path: "M 0.125,-0.125 0.125,0.125",
  strokeOpacity: 1,
  scale: 4,
}

export const DEFAULT_LINE_CONFIG = {
  strokeWeight: 5,
  strokeOpacity: 1,
}

export const resolveLineConfig = (
  step: TripStep
): Partial<google.maps.PolylineOptions> => {
  const walkMode = step.details.type === StepType.ByFoot
  const options: Partial<google.maps.PolylineOptions> = {
    geodesic: true,
    strokeColor: resolveLineColorFromStep(step),
    strokeWeight: DEFAULT_LINE_CONFIG.strokeWeight,
    strokeOpacity: DEFAULT_LINE_CONFIG.strokeOpacity,
  }

  if (walkMode) {
    Object.assign(options, {
      strokeOpacity: 0,
      icons: [{ icon: lineSymbol, offset: "0", repeat: "20px" }],
    })
  }
  return options
}
