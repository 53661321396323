import { AppFunctionComponent } from "@vooom/core"
import React, { useState, useContext, useEffect, useCallback } from "react"
import { v4 as uuidv4 } from "uuid"
import AwesomeDebouncePromise from "awesome-debounce-promise"
import DatePicker from "react-date-picker"
import dayjs from "dayjs"

import { LocationPoiParam } from "../../interfaces/trip-request-body.interface"
import { PoiSuggestion } from "../../interfaces/suggestion.interface"
import { SearchInput } from "./search-input/search-input.component"
import {
  BackIcon,
  InputDivider,
  InputsContainer,
  SearchContainer,
  SwitchIcon,
  NotFound,
  DateAndTimeContainer,
  LocationsContainer,
  TimeInput,
  TimeTypeContainer,
} from "./trips-search.styles"
import switchIcon from "@vooom/images/switch-icon.png"
import leftArrow from "@vooom/images/left-arrow.png"
import { SearchButtonArea } from "./search-button-area/search-button-area.component"
import { useSuggestions } from "../../services/suggestions.hook"
import { AddressSuggestions } from "./address-suggestions/address-suggestions.component"
import {
  SearchFields,
  SearchTripsParams,
  SearchValues,
  SearchValuesPois,
  TimeType,
  TripsSearchPoi,
} from "./trips-search.definitions"
import { saveSuggestionToStorage } from "../../services/poi-suggestions-storage"
import { LocationContext } from "../../contexts/location.context"
import { useLocation } from "react-router-dom"
import { isDefined } from "@vooom/utils"
import { SearchTimeTypeSelect } from "./search-time-type-select/search-time-type-select"

interface TripsSearchProps {
  disableTripsSearching: boolean
  pending: boolean
  onBack: () => void
  onSearchTrip: (params: SearchTripsParams) => void
  anyTripFound: boolean
}

// Requirement of the lib - to define it "outside" the component
const loadSuggestions = async (callback: () => Promise<void> | undefined) => {
  await callback()
}
const loadSuggestionsDebounced = AwesomeDebouncePromise(loadSuggestions, 300)

export const TripsSearch: AppFunctionComponent<TripsSearchProps> = (props) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [initFrom, initTo, initDate, initTime, initTimeType] = [
    params.get("from"),
    params.get("to"),
    params.get("date"),
    params.get("time"),
    params.get("timeType") as TimeType,
  ]
  const { isLocationEnabled, currentLocation } = useContext(LocationContext)
  const [pois, setPois] = useState<SearchValuesPois>({})
  const [showSuggestionsFor, setShowSuggestionsFor] = useState<
    SearchFields | undefined
  >()
  const [searchPerformed, setSearchPerformed] = useState(false)

  const actualDate = new Date()
  const validInitTime = isDefined(initTime)
    ? initTime
    : dayjs(actualDate).format("HH:mm")
  const [values, setValues] = useState<SearchValues<string>>({
    from: "",
    to: "",
    date: isDefined(initDate) ? new Date(initDate) : actualDate,
    time: validInitTime,
    timeType: initTimeType || "departure",
  })

  const {
    getSuggestions,
    suggestionsResult,
    pending,
    error,
    clearSuggestions,
  } = useSuggestions()

  const onFocus = (fieldSource: SearchFields) => {
    setShowSuggestionsFor(fieldSource)
    if (pois[fieldSource]?.usesCurrentLocation) {
      setValues((currentValues) => ({
        ...currentValues,
        [fieldSource]: "",
      }))
      setPois((currentPois) => ({
        ...currentPois,
        [fieldSource]: undefined,
      }))
    }
  }

  const resetSuggestionsState = () => {
    clearSuggestions()
    setShowSuggestionsFor(undefined)
  }

  const onDateChange = (date: Date) => {
    setValues((currentValues) => ({
      ...currentValues,
      date,
    }))
  }

  const onTimeChange = (time: string) => {
    setValues((currentValues) => ({
      ...currentValues,
      time,
    }))
  }

  const onTimeTypeChange = (timeType: TimeType) => {
    setValues((currentValues) => ({
      ...currentValues,
      timeType,
    }))
  }

  const onInputValueChange = async (
    value: string,
    fieldSource: SearchFields
  ) => {
    setSearchPerformed(false)
    setValues((currentValues) => ({
      ...currentValues,
      [fieldSource]: value,
    }))
    setPois((currentPois) => ({
      ...currentPois,
      [fieldSource]: undefined,
    }))

    setShowSuggestionsFor(fieldSource)

    await loadSuggestionsDebounced(() =>
      getSuggestions({
        address: value,
        sessionToken: uuidv4(),
      })
    )
  }

  const [valuesToFill, setValuesToFill] = useState<
    Record<SearchFields, string | null>
  >({
    from: initFrom,
    to: initTo,
  })

  const setNextValueToFill = useCallback(() => {
    const valuesToFillPairs = Object.entries(valuesToFill)
      .map(([key, value]): [SearchFields, string] | undefined => {
        if (!isDefined(value)) {
          return undefined
        }
        return [key, value] as [SearchFields, string]
      })
      .filter(isDefined)
    if (valuesToFillPairs.length === 0) {
      return
    }
    const [nextValue] = valuesToFillPairs
    const [key, value] = nextValue
    onInputValueChange(value, key)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesToFill])

  useEffect(() => {
    setNextValueToFill()
  }, [valuesToFill, setNextValueToFill])

  const onSwitchClick = () => {
    setValues((currentValues) => ({
      ...currentValues,
      from: currentValues.to,
      to: currentValues.from,
    }))

    setPois((currentPois) => ({
      from: currentPois.to,
      to: currentPois.from,
    }))
    setSearchPerformed(false)
  }

  const onPoiClick = (item: PoiSuggestion, fieldSource: SearchFields) => {
    const newPoiValue: TripsSearchPoi = {
      poi: item,
    }

    setValues((currentValues) => ({
      ...currentValues,
      [fieldSource]: item.address,
    }))
    setPois((currentPois) => ({
      ...currentPois,
      [fieldSource]: newPoiValue,
    }))
    resetSuggestionsState()
    setSearchPerformed(false)
    setValuesToFill({
      ...valuesToFill,
      [fieldSource]: null,
    })
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }

  const onLocationClick = (fieldSource: SearchFields) => {
    const newPoiValue: TripsSearchPoi = {
      usesCurrentLocation: true,
    }

    if (fieldSource === "from" && pois.to?.usesCurrentLocation === true) {
      onDeleteClick("to")
    }
    if (fieldSource === "to" && pois.from?.usesCurrentLocation === true) {
      onDeleteClick("from")
    }

    setValues((currentValues) => ({
      ...currentValues,
      [fieldSource]: "Aktualna lokalizacja",
    }))
    setPois((currentPois) => ({
      ...currentPois,
      [fieldSource]: newPoiValue,
    }))
    setSearchPerformed(false)
  }

  const onDeleteClick = (fieldSource: SearchFields) => {
    setValues((currentValues) => ({
      ...currentValues,
      [fieldSource]: "",
    }))
    setPois((currentPois) => ({
      ...currentPois,
      [fieldSource]: undefined,
    }))
    setSearchPerformed(false)
  }

  const onSearchClick = () => {
    if (!pois.from || !pois.to) {
      return
    }

    if (pois.from.poi) {
      saveSuggestionToStorage(pois.from.poi)
    }

    if (pois.to.poi) {
      saveSuggestionToStorage(pois.to.poi)
    }

    const resolveParam = (item: TripsSearchPoi): LocationPoiParam => {
      if (item.usesCurrentLocation) {
        return {
          location: currentLocation,
        }
      } else if (item.poi) {
        return {
          placeId: item.poi.place_id,
        }
      }

      throw new Error("Oups, something went wrong")
    }
    const mergedDateAndTime = new Date(
      `${values.date.toDateString()}, ${values.time}`
    )
    setSearchPerformed(true)
    props.onSearchTrip({
      from: resolveParam(pois.from),
      to: resolveParam(pois.to),
      location: currentLocation,
      timeType: values.timeType,
      date: mergedDateAndTime,
    })
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <SearchContainer disabled={props.disableTripsSearching}>
          {props.disableTripsSearching && (
            <BackIcon variant="small" src={leftArrow} onClick={props.onBack} />
          )}
          <LocationsContainer>
            <InputsContainer>
              <SearchInput
                disabled={props.disableTripsSearching}
                usesCurrentLocation={Boolean(pois.from?.usesCurrentLocation)}
                fieldIdentifier="from"
                isLocationEnabled={isLocationEnabled}
                label="Wpisz adres początkowy"
                value={values.from}
                onFocus={onFocus}
                onChange={onInputValueChange}
                onLocationClick={onLocationClick}
                onDeleteClick={onDeleteClick}
              />
              <InputDivider />

              <SearchInput
                disabled={props.disableTripsSearching}
                usesCurrentLocation={Boolean(pois.to?.usesCurrentLocation)}
                fieldIdentifier="to"
                isLocationEnabled={isLocationEnabled}
                label="Wpisz adres docelowy"
                value={values.to}
                onFocus={onFocus}
                onChange={onInputValueChange}
                onLocationClick={onLocationClick}
                onDeleteClick={onDeleteClick}
              />
            </InputsContainer>
            {!props.disableTripsSearching && (
              <SwitchIcon src={switchIcon} onClick={onSwitchClick} />
            )}
            {!props.disableTripsSearching &&
              suggestionsResult &&
              showSuggestionsFor && (
                <AddressSuggestions
                  source={showSuggestionsFor}
                  suggestionsResult={suggestionsResult}
                  pending={pending}
                  onTurnOffRequest={resetSuggestionsState}
                  onItemClick={onPoiClick}
                  error={error}
                />
              )}
          </LocationsContainer>

          <TimeTypeContainer>
            <SearchTimeTypeSelect
              id="timeType"
              name="timeType"
              value={values.timeType}
              onChange={onTimeTypeChange}
            />
          </TimeTypeContainer>

          <DateAndTimeContainer>
            <DatePicker
              onChange={(date) => {
                if (Array.isArray(date)) {
                  return
                }
                onDateChange(date)
              }}
              returnValue="start"
              value={values.date}
            />
            <TimeInput
              type="time"
              id="time"
              name="time"
              required
              value={values.time}
              onChange={(event) => onTimeChange(event.target.value)}
            />
          </DateAndTimeContainer>
        </SearchContainer>

        {!props.disableTripsSearching && pois.from && pois.to && (
          <>
            <SearchButtonArea
              onSearchClick={onSearchClick}
              disabled={props.pending}
            />

            {/* this is far from perfect; TODO refactor once we switch to stage management / contexts
             It rather should be outside trip search, as well as suggestions; it however needs to react on input changes
            */}
            {searchPerformed && !props.pending && !props.anyTripFound && (
              <NotFound>
                Nic nie znaleźliśmy{" "}
                <span role="img" aria-label="o nie!">
                  😱
                </span>
              </NotFound>
            )}
          </>
        )}
      </form>
    </>
  )
}
