import { AppFunctionComponent } from "@vooom/core"
import { useGoogleMap } from "@react-google-maps/api"
import { MapVehicle } from "../../../../interfaces/map-vehicle.interface"
import { isDefined } from "../../../../utils/is-defined"
import { resolveVehiclePinIcon } from "./resolve-vehicle-pin-icon"
import MarkerClusterer from "@google/markerclustererplus"
import aggregator from "../../../../images/pins/aggregator-pin.svg"
import { useEffect } from "react"
import { useVehicles } from "../../../../services/vehicles.hook"

interface Props {
  onMouseOver: (vehicle: MapVehicle) => void
}

export const VehiclesDrawer: AppFunctionComponent<Props> = ({
  onMouseOver = () => {},
}) => {
  const map = useGoogleMap()
  const { vehicles, fetchVehicles } = useVehicles(
    parseInt(process.env.REACT_APP_VEHICLES_REFETCH_MS || "30000", 10)
  )

  useEffect(() => {
    if (isDefined(map)) {
      const handleOnIdle = () => {
        const bounds = map.getBounds()
        if (!isDefined(bounds)) {
          return
        }
        const ne = bounds.getNorthEast()
        const sw = bounds.getSouthWest()
        fetchVehicles({
          ne: {
            latitude: ne.lat(),
            longitude: ne.lng(),
          },
          sw: {
            latitude: sw.lat(),
            longitude: sw.lng(),
          },
        })
      }

      const markers = vehicles.map((vehicle) => {
        const marker = new google.maps.Marker({
          position: {
            lat: vehicle.latitude,
            lng: vehicle.longitude,
          },
          icon: resolveVehiclePinIcon(vehicle),
        })
        marker.addListener("mouseover", () => {
          onMouseOver(vehicle)
        })
        return marker
      })
      const clusterer = new MarkerClusterer(map, markers, {
        minimumClusterSize: 10,
        averageCenter: false,
        gridSize: 220,
        batchSize: 20000,
        batchSizeIE: 1000,
        maxZoom: 20,
        enableRetinaIcons: true,
        imageExtension: "svg",
        zIndex: -1, // INFO: To prevent overlaying on vehicle details
        styles: [
          {
            textColor: undefined,
            url: aggregator,
            width: 45,
            height: 63,
          },
        ],
      })
      const onIdleListener = map.addListener("idle", handleOnIdle)
      return () => {
        onIdleListener.remove()
        setTimeout(() => {
          clusterer.clearMarkers()
        }, 400)
      }
    }
  })
  return null
}
