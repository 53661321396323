import { AppFunctionComponent } from "@vooom/core"
import React from "react"
import { Trip } from "../../interfaces/trip.interface"
import { StepDetails } from "./step-details"
import { StepHeader } from "./step-header"
import { Scrollable, StepContainer, Divider } from "./trip-details.styles"

interface TripDetailsProps {
  trip: Trip
}

export const TripDetails: AppFunctionComponent<TripDetailsProps> = ({
  trip,
}) => (
  <Scrollable>
    {trip.steps.map((segment, index) => {
      const isLastStep = index === trip.steps.length - 1
      return (
        <React.Fragment key={index}>
          <StepContainer>
            <StepHeader step={segment} />
            <StepDetails step={segment} isLastSegment={isLastStep} />
          </StepContainer>
          {!isLastStep && (
            <div>
              <Divider />
            </div>
          )}
        </React.Fragment>
      )
    })}
  </Scrollable>
)
