import { AppFunctionComponent } from "@vooom/core"
import React from "react"
import styled from "styled-components"

const Svg = styled.svg`
  min-width: 16px;
  min-height: 16px;
  height: 16px;
  width: 16px;
`

export const StopDot: AppFunctionComponent<{}> = () => (
  <Svg viewBox="0 0 16 16">
    <circle cx={8} cy={8} r={4} fill={"gray"} />
  </Svg>
)
