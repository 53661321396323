import { useState, useCallback, useEffect } from "react"
import { MapVehicle } from "../interfaces/map-vehicle.interface"
import {
  GraphQlResponse,
  isDefined,
  isSuccessGraphQlResponse,
} from "@vooom/utils"
import { Location } from "../interfaces/location.interface"
import { useNotifier } from "./notifier.hook"
import { useLogger } from "./logger/logger.hook"
import { VehicleType } from "../interfaces/vehicle-type.enum"
import { useLanguage } from "../language/use-language"

interface FetchVehiclesOptions {
  ne: Location
  sw: Location
}

interface ResponseVehicle {
  type: VehicleType
  longitude: number
  latitude: number
  locationName: string
  range?: number
  powerSourcePercentage?: number
  provider: {
    logotype?: string
    id: string
  }
  providerVehicleId: string
  displayName: string
  displayIdentifier: string
  image?: string
}

interface VehicleResponseData {
  vehicles: ResponseVehicle[]
}

const mapResponseToVehicles = (response: VehicleResponseData): MapVehicle[] =>
  response.vehicles.map((vehicle) => ({
    id: `${vehicle.provider.id}_${vehicle.providerVehicleId}`,
    displayIdentifier: vehicle.displayIdentifier,
    displayName: vehicle.displayName,
    latitude: vehicle.latitude,
    locationName: vehicle.locationName,
    longitude: vehicle.longitude,
    provider: {
      logotype: vehicle.provider.logotype,
    },
    type: vehicle.type,
    image: vehicle.image,
    powerSourcePercentage: vehicle.powerSourcePercentage,
    range: vehicle.range,
  }))

export const useVehicles = (timeoutInMs: number) => {
  const [pending, setPending] = useState(false)
  const [error, setError] = useState<Error>()
  const [vehicles, setCurrentVehicles] = useState<MapVehicle[]>([])
  const [lastFetchTimeInMs, setLastFetchTime] = useState<number>()
  const [lastOptions, setLastOptions] = useState<FetchVehiclesOptions>()
  const notifier = useNotifier()
  const logger = useLogger()
  const language = useLanguage()

  const fetchVehicles = useCallback(
    (options: FetchVehiclesOptions) => {
      setError(undefined)
      setPending(true)
      const { ne, sw } = options
      const query = `query MyQuery {
          vehicles(
            where: {
              _and: [
                {
                  latitude: {
                    _gte: ${sw.latitude}
                  },
                  longitude: {
                    _gte: ${sw.longitude}
                  }
                }, {
                  latitude: {
                    _lte: ${ne.latitude}
                  },
                  longitude: {
                    _lte: ${ne.longitude}
                  }
                }
              ]
            }
          ) {
            latitude
            longitude
            type
            image
            locationName
            range
            displayIdentifier
            displayName
            powerSourcePercentage
            providerVehicleId
            provider {
              id
              logotype
            }
          }
      }
      `

      return fetch(`${process.env.REACT_APP_GRAPHQL_URL}/v1/graphql`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Accept-Language": language,
        },
        body: JSON.stringify({
          query,
        }),
      })
        .then((rawResponse) => rawResponse.json())
        .then((response: GraphQlResponse<VehicleResponseData>) => {
          if (!isSuccessGraphQlResponse(response)) {
            return Promise.reject(response)
          }

          const vehicles: MapVehicle[] = mapResponseToVehicles(response.data)
          setCurrentVehicles(vehicles)
          setLastFetchTime(Date.now())
          setLastOptions(options)
        })
        .catch((error) => {
          setError(error)
          notifier.error("Nie mogliśmy pobrać pojazdów, spróbuj za chwilę 🦅")
          logger.error("Unknown error occurred when fetching vehicles", {
            error,
          })
        })
        .finally(() => setPending(false))
    },
    [logger, notifier, language]
  )

  useEffect(() => {
    const interval = setInterval(() => {
      const isDelayValid =
        !isDefined(lastFetchTimeInMs) ||
        lastFetchTimeInMs + timeoutInMs <= Date.now()
      if (isDelayValid && isDefined(lastOptions)) {
        fetchVehicles(lastOptions)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [fetchVehicles, lastFetchTimeInMs, lastOptions, timeoutInMs])

  return { pending, error, vehicles, fetchVehicles }
}
