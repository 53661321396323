import styled from "styled-components"
import { Icon } from "../../../../../icon/icon"

export const Root = styled.div`
  background: none;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  align-items: center;
`

export const Arrow = styled(Icon)`
  margin-left: 10px;
  margin-right: 10px;
`

export const Spacer = styled.div`
  min-width: 35px;
  min-height: 1px;
`
