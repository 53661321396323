import React, { FunctionComponentElement, useMemo } from "react"
import { AppFunctionComponent } from "@vooom/core"
import { TripStep } from "../../interfaces/trip-step.interface"
import start from "@vooom/images/start.png"
import finish from "@vooom/images/finish.png"
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api"
import { Trip } from "../../interfaces/trip.interface"
import { StepType } from "../../enums/step-type.enum"
import { resolveStepPoiIcon } from "./mappers/resolve-step-poi-icon"
import { MapFitter } from "./route/map-fitter"
import { TripsPolylines } from "./trip-polyline/trips-polylines.component"
import { Location } from "../../interfaces/location.interface"
import { Vehicles } from "./vehicles"

interface MapProps {
  center: Location
  zoom: number
  selectedTrip?: Trip
  trips?: Trip[]
  hoveredTripId?: number
}

const resolveStepsPois = (
  steps: TripStep[] = []
): FunctionComponentElement<any>[] => {
  const pois: FunctionComponentElement<any>[] = []
  let i = 0
  for (let step of steps) {
    if (i === 0) {
      pois.push(
        <Marker
          key={i++}
          position={{
            lat: step.details.startPoint.latitude,
            lng: step.details.startPoint.longitude,
          }}
          icon={start}
        />
      )
      continue
    }
    pois.push(
      <Marker
        key={i++}
        position={{
          lat: step.details.startPoint.latitude,
          lng: step.details.startPoint.longitude,
        }}
        icon={
          step.type === StepType.ByFoot ? undefined : resolveStepPoiIcon(step)
        }
      />
    )
  }

  if (steps.length > 0) {
    pois.push(
      <Marker
        key={i++}
        position={{
          lat: steps[steps.length - 1].details.stopPoint.latitude,
          lng: steps[steps.length - 1].details.stopPoint.longitude,
        }}
        icon={finish}
      />
    )
  }
  return pois
}
const libraries = process.env.REACT_APP_MAP_LIBRARIES?.split(",")

export const PlannerMapComponent: AppFunctionComponent<MapProps> = (props) => {
  const { zoom, selectedTrip: trip, trips, center, hoveredTripId } = props
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY ?? "",
    libraries,
  })

  // has to be memonized - without it, it would set center on every refresh
  const memonizedCenter = useMemo(
    () => ({
      lat: center.latitude,
      lng: center.longitude,
    }),
    [center]
  )

  if (!isLoaded) {
    return <div> Loading map ... </div>
  }

  return (
    <GoogleMap
      zoom={zoom}
      center={memonizedCenter}
      mapContainerStyle={{
        height: "100%",
        width: "100%",
      }}
      options={{
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
      }}
    >
      {!trip && (
        <TripsPolylines trips={trips ?? []} activeTripId={hoveredTripId} />
      )}
      {trip && (
        <>
          <MapFitter trip={trip} />
          <TripsPolylines trips={[trip]} activeTripId={trip.id} />
        </>
      )}
      {resolveStepsPois(trip?.steps ?? [])}
      <Vehicles />
    </GoogleMap>
  )
}
