import { AppFunctionComponent } from "@vooom/core"
import React from "react"
import styled, { css } from "styled-components"
import { shadowColor } from "@vooom/ui"

const getSquareIconSize = (size: number) => css`
  width: ${size}px;
  height: ${size}px;
`
const IconElement = styled.img<{
  variant: SizeVariant
  shape: ShapeVariant
  shadowed: boolean
}>`
  width: 24px;
  height: 24px;
  object-fit: contain;

  ${(props) => {
    switch (props.variant) {
      case "big":
        return getSquareIconSize(42)
      case "medium":
        return getSquareIconSize(32)
      case "small":
        return getSquareIconSize(24)
      case "tiny":
        return getSquareIconSize(18)
      default:
        return
    }
  }}

  ${(props) =>
    props.shape === "round" &&
    css`
      border-radius: 100%;
    `}

  ${(props) =>
    props.shadowed &&
    css`
      box-shadow: 0px 0px 20px 0px ${shadowColor("box")};
    `}

  ${(props) =>
    Boolean(props.onClick) &&
    css`
      cursor: pointer;
    `}
`

type SizeVariant = "tiny" | "small" | "big" | "medium"
type ShapeVariant = "square" | "round"

interface IconProps {
  src: string
  variant?: SizeVariant
  shadowed?: boolean
  shape?: ShapeVariant
  onClick?: () => void
}

export const Icon: AppFunctionComponent<IconProps> = ({
  onClick,
  className,
  variant = "small",
  shadowed = false,
  shape = "square",
  src,
}) => (
  <IconElement
    shadowed={shadowed}
    shape={shape}
    onClick={onClick}
    variant={variant}
    src={src}
    className={className}
  />
)
