import { TripStep } from "../../../../../../../interfaces/trip-step.interface"
import React from "react"
import { AppFunctionComponent } from "@vooom/core"
import { TripStepDetails } from "../../../../../../../interfaces/trip-step-details"
import { resolveStepPoiIcon } from "../../../../../../map/mappers/resolve-step-poi-icon"
import { LabeledIcon, LabeledIconVariant } from "../../../../../../labeled-icon"
import { durationToText } from "@vooom/utils"

type Props = {
  step: TripStep<TripStepDetails>
}

export const StandardStep: AppFunctionComponent<Props> = ({ step }) => {
  const icon = resolveStepPoiIcon(step)
  return (
    <LabeledIcon
      label={durationToText(step.details.duration)}
      variant={LabeledIconVariant.Smoothy}
      icon={icon}
    />
  )
}
