import { toast } from "react-toastify"

export const useNotifier = () => {
  const notify = (toastId: string, callback: () => void) => {
    if (!toast.isActive(toastId)) {
      callback()
    }
  }

  return {
    error: (message: string) => {
      const toastId = `error_${message}`
      notify(toastId, () =>
        toast.error(message, {
          toastId,
        })
      )
    },
    success: (message: string) => {
      const toastId = `success_${message}`
      notify(toastId, () =>
        toast.success(message, {
          toastId,
        })
      )
    },
    info: (message: string) => {
      const toastId = `info_${message}`
      notify(toastId, () =>
        toast.info(message, {
          toastId,
        })
      )
    },
  }
}
