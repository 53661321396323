import { TripStep } from "../../../interfaces/trip-step.interface"
import { resolveStepPoiIcon } from "../../map/mappers/resolve-step-poi-icon"
import { LabeledIcon, LabeledIconVariant } from "../../labeled-icon"
import { LabeledText } from "../../labeled-text/labeled-text"
import { isPublicTransport, isDefined, isWalk, isVehicle } from "@vooom/utils"
import dayjs from "dayjs"
import React from "react"
import { AppFunctionComponent } from "@vooom/core"
import { Root, TripTargetInfo } from "./step-header.styles"

// TODO tell if this is shadowed PublicTransport
const resolveLabel = (step: TripStep): [string, LabeledIconVariant] => {
  if (isPublicTransport(step)) {
    return [step.details.label ?? "", LabeledIconVariant.Outlined]
  }

  if (isVehicle(step)) {
    return [step.details.provider, LabeledIconVariant.Outlined]
  }

  if (isWalk(step)) {
    return ["kroki: " + step.details.footsteps, LabeledIconVariant.Smoothy]
  }

  return ["", LabeledIconVariant.Outlined]
}

interface Props {
  step: TripStep
}

export const StepHeader: AppFunctionComponent<Props> = ({ step }) => {
  const icon = resolveStepPoiIcon(step)
  const [label, variant] = resolveLabel(step)
  let Departure = null

  if (isPublicTransport(step)) {
    const departureLabel = isDefined(step.details.departure) ? `za: ` : `co: `

    const departureText = isDefined(step.details.departure)
      ? `${step.details.departures
          .map((dep) => dayjs(dep).diff(new Date(), "minute").toString())
          .join(",")} min`
      : `${step.details.interval?.minutes} min`

    Departure = <LabeledText prefix={departureLabel} text={departureText} />
  }

  return (
    <Root>
      <LabeledIcon icon={icon} label={label} variant={variant} />
      <TripTargetInfo>{Departure}</TripTargetInfo>
    </Root>
  )
}
