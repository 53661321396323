import React, { FunctionComponent } from "react"
import { ThemeProvider } from "styled-components"
import { Normalize } from "styled-normalize"
import GlobalStyle from "../global-style.component"
import theme from "../../theme/theme"

export interface LayoutProps {
  readonly title?: string
  readonly seoTitle?: string
  readonly description?: string
  readonly footerAdditionalBackground?: string
  readonly withSeoTitleTemplate?: boolean
  readonly seoImage?: string
}

const RootLayout: FunctionComponent<LayoutProps> = ({
  children,
  title,
  seoTitle,
  description,
  withSeoTitleTemplate,
  seoImage,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Normalize />
      <GlobalStyle />
      {children}
    </ThemeProvider>
  )
}

export default RootLayout
