import React from "react"
import { AppFunctionComponent } from "@vooom/core"
import { PoiSuggestion } from "../../../interfaces/suggestion.interface"
import {
  SuggestionsResult,
  SuggestionResultType,
} from "../../../services/suggestions.hook"
import { SearchFields } from "../trips-search.definitions"
import { Suggestions } from "./suggestions/suggestions.component"
import locationIcon from "@vooom/images/location.png"
import locationClockIcon from "@vooom/images/location-clock.png"
import { SuggestionVisualItem } from "./suggestions/suggestions-item.component"
import styled from "styled-components"
import { backgroundColor, shadowColor } from "@vooom/ui"
import { Loader } from "../../loader/loader.component"

interface AutoSuggestionProps {
  source: SearchFields
  suggestionsResult: SuggestionsResult
  onTurnOffRequest: () => void
  onItemClick: (item: PoiSuggestion, source: SearchFields) => void
  pending: boolean
  error?: any
}

const resolveSuggestionsVisualData = (type: SuggestionResultType) => {
  const pairs: Record<SuggestionResultType, { label: string; icon: string }> = {
    [SuggestionResultType.Geocoder]: {
      label: "Podpowiedzi",
      icon: locationClockIcon,
    },
    [SuggestionResultType.Local]: {
      label: "Ostatnie",
      icon: locationIcon,
    },
  }
  return pairs[type]
}

const Wrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0px;
  background-color: ${backgroundColor("light")};
  padding: 20px;
  border-radius: 10px;
  z-index: 2;
  box-shadow: 0 4px 20px 0 ${shadowColor("box")};
`

export const AddressSuggestions: AppFunctionComponent<AutoSuggestionProps> = (
  props
) => {
  const onItemClick = (item: PoiSuggestion) => {
    props.onItemClick(item, props.source)
  }

  const {
    items: suggestionItems,
    type: suggestionsSource,
  } = props.suggestionsResult

  let content
  const showNothingFoundLabel =
    suggestionsSource === SuggestionResultType.Geocoder &&
    suggestionItems.length === 0

  if (props.pending) {
    content = <Loader variant="big" />
  } else {
    const { label, icon } = resolveSuggestionsVisualData(suggestionsSource)

    const preparedItems: SuggestionVisualItem[] = suggestionItems.map(
      (item) => ({
        ...item,
        icon,
      })
    )

    content = (
      <Suggestions
        showNothingFoundLabel={showNothingFoundLabel}
        label={label}
        items={preparedItems}
        onItemClick={onItemClick}
        onTurnOffRequest={props.onTurnOffRequest}
      />
    )
  }

  return <Wrapper>{content}</Wrapper>
}
