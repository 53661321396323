import styled from "styled-components"

export const Root = styled.div`
  display: flex;
  direction: row;
`

export const ProviderLogotype = styled.img`
  max-width: 70px !important;
  position: relative;
  padding: 5px;
`

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: end;
  flex: 1 1 auto;
`

export const Image = styled.img`
  max-width: 100% !important;
  position: relative;
`

export const ImageContainer = styled.div`
  min-width: 50%;
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FuelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  > img {
    margin-right: 5px;
  }
`
