import { TripStep } from "../../../interfaces/trip-step.interface"
import { TripStepDetailsPublicTransport } from "../../../interfaces/trip-step-details-public-transport.interface"
import { PublicTransportType } from "../../../enums/public-transport-type.enum"
import { L10n, translate, durationToText } from "@vooom/utils"
import { TripStepDetails } from "../../../interfaces/trip-step-details"
import { Duration } from "iso8601-duration"

export const publicTransportTitle = (
  step: TripStep<TripStepDetailsPublicTransport>,
  humanReadableDurationConverted: (
    duration?: Duration
  ) => string = durationToText
): string => {
  const stopsCount = step.details.stops.length
  const translatedTitle =
    step.details.transportType === PublicTransportType.metro
      ? translate(L10n.station, stopsCount)
      : translate(L10n.stop, stopsCount)
  const duration = humanReadableDurationConverted(step.details.duration)
  const time = `(${duration})`

  return `${translatedTitle}: ${stopsCount} ${time}`
}

export const transportTitle = (
  details: Pick<TripStepDetails, "duration">,
  humanReadableDurationConverted: (
    duration?: Duration
  ) => string = durationToText
): string => humanReadableDurationConverted(details.duration)
