import styled from "styled-components"
import { textColor } from "../../../../../../@ui/theme/theme-getters"

export const Root = styled.div`
  display: flex;
  flex-direction: column;
`

export const DisplayName = styled.p`
  margin-top: 0.5rem;
  font-size: 1.25rem;
  margin: 0px;
  font-weight: normal;
`

export const DisplayIdentifier = styled.p`
  margin-top: 2px;
  font-size: 1rem;
  color: ${textColor("paragraph")};
`
