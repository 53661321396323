import { useState, useRef, useEffect } from "react"
import { Key } from "ts-key-enum"

export const useComponentVisible = (initialIsVisible: boolean) => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const ref = useRef<HTMLDivElement>(null)

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === Key.Escape) {
      setIsComponentVisible(false)
    }
  }

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true)
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true)
      document.removeEventListener("click", handleClickOutside, true)
    }
  })

  return { ref, isComponentVisible }
}
