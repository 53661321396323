import * as SentryBrowser from "@sentry/browser"

export type SentryObject = typeof SentryBrowser

export const initializeSentryObject = (
  dsn: string
): SentryObject | undefined => {
  try {
    SentryBrowser.init({
      dsn,
      normalizeDepth: 10,
    })

    return SentryBrowser
  } catch (err) {
    console.warn("Cannot initialize sentry", err)
  }
}
