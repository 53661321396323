import { AppFunctionComponent } from "@vooom/core"
import React from "react"
import { useGeolocation } from "../../services/user-locator.hook"
import { LocationContext } from "../../contexts/location.context"
import { BrowserGeolocation } from "../../interfaces/browser-geolocation.interface"

export const LocationRoot: AppFunctionComponent<{}> = ({ children }) => {
  const locationContextValue: BrowserGeolocation = useGeolocation()

  return (
    <LocationContext.Provider value={locationContextValue}>
      {children}
    </LocationContext.Provider>
  )
}
